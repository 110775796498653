.image-hover {
  overflow: hidden;

  img {
    object-fit: cover;
    transition: transform $default-timing;
  }

  &:hover {
    img:not(.no-transform) {
      transform: scale(102%);
    }
  }
}
